// libraries
import styled from 'styled-components';
// constants
import { COLORS } from 'components/UI/Theme';

export const SliderPagination = styled.ul.attrs({
    className: 'splide__pagination',
})`
    position: relative;
    padding: 32px 0 0;

    li {
        margin-left: 16px;
        line-height: 0;

        &:first-child {
            margin-left: 0;
        }
    }

    li > button {
        width: 8px;
        height: 8px;
        padding: 0;
        background-color: ${COLORS.primary};
        border: none;
        border-radius: 999px;
        opacity: 1;
        transition: width 0.3s;

        &.is-active {
            width: 64px;
            background-color: ${COLORS.primary};
        }
    }
`;
