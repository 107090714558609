// libraries
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
// components
import Title from 'components/UI/Title';
import { BREAKPOINT, Container } from 'components/UI/Grid';
// constants
import { COLORS } from 'components/UI/Theme';

export const Promo = () => {
    return (
        <StyledPromoSection>
            <Container>
                <StyledPromoTitle as="h1" variant="h1">
                    Меняем мир и ваши бизнес-процессы
                </StyledPromoTitle>
                <StaticImage
                    src="../../../images/common/promobg.png"
                    alt="Лучшие решения для вашего бизнеса"
                    loading="eager"
                    className="main-image"
                    objectFit="contain"
                    width={874}
                    height={370}
                    placeholder="blurred"
                />
            </Container>
        </StyledPromoSection>
    );
};

const StyledPromoSection = styled.section`
    position: relative;
    min-height: 300px;
    padding-top: 40px;
    overflow: hidden;

    ${BREAKPOINT.sm} {
        min-height: 480px;
        padding-top: 80px;
    }

    .main-image {
        position: absolute;
        bottom: 0;
        display: block;
        width: 100%;
        height: auto;

        ${BREAKPOINT.md} {
            right: -150px;
        }
    }
`;

const StyledPromoTitle = styled(Title)`
    z-index: 2;
    max-width: 300px;
    margin: 0;
    color: ${COLORS.primary};
    font-weight: 400;
    font-size: 28px;
    font-style: normal;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        max-width: 350px;
        font-size: 36px;
    }

    ${BREAKPOINT.md} {
        position: absolute;
        top: 20%;
        left: auto;
        max-width: 425px;
        font-size: 36px;
    }

    ${BREAKPOINT.md} {
        top: 30%;
        font-size: 42px;
    }

    ${BREAKPOINT.xl} {
        top: 40%;
        max-width: 450px;
        font-size: 48px;
    }
`;
