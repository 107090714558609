// libraries
import React from 'react';
import styled from 'styled-components';
import 'modern-normalize/modern-normalize.css';
// components
import Layout from 'components/Layout';
import { SEO } from 'components/SEO';
import { Promo } from 'components/Blocks/Promo';
import { Technology } from 'components/Blocks/Technology';
import { Directions } from 'components/Blocks/Directions';
import { WhyUs } from 'components/Blocks/WhyUs';
import { About } from 'components/Blocks/About';
import { ParticlesBlock } from 'components/Particles';
import { FeedbackForm } from 'components/Blocks/FeedbackForm';
// hooks
import { ViewportProvider } from 'utils/hooks/useViewport';

const IndexPage = () => {
    return (
        <ViewportProvider>
            <Layout>
                <StyledMainSection>
                    <ParticlesBlock />
                    <Promo />
                    <Directions />
                    <Technology />
                </StyledMainSection>
                <WhyUs />
                <FeedbackForm />
                <About />
            </Layout>
        </ViewportProvider>
    );
};

const StyledMainSection = styled.div`
    /* background: top center no-repeat url('backgrounds/idflab-main-background.png'); */
`;

export default IndexPage;

export const Head = () => {
    return <SEO />;
};
