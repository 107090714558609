// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { BREAKPOINT, Col } from 'components/UI/Grid';
// constants
import { COLORS } from 'components/UI/Theme';

export const ProjectItem = ({ id, title, domen, subtitle, description, since, info }) => {
    return (
        <StyledProjectItem key={`${id}_project`}>
            <StyledProjectTitle dangerouslySetInnerHTML={{ __html: title }} />
            <StyledDomenName dangerouslySetInnerHTML={{ __html: domen }} />
            <StyledSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
            <StyledDescription dangerouslySetInnerHTML={{ __html: description }} />
            <StyledFigureBlock>
                <StyledFigureContent>
                    <StyledFigureTitle dangerouslySetInnerHTML={{ __html: since.year }} />
                    <StyledFigureSubitle dangerouslySetInnerHTML={{ __html: since.caption }} />
                </StyledFigureContent>
                <StyledFigureContent>
                    <StyledFigureTitle dangerouslySetInnerHTML={{ __html: info.amount }} />
                    <StyledFigureSubitle dangerouslySetInnerHTML={{ __html: info.namely }} />
                </StyledFigureContent>
            </StyledFigureBlock>
        </StyledProjectItem>
    );
};

const StyledProjectItem = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0;
    padding: 16px 32px;
    background: #fff;
    border-radius: 20px;

    ${BREAKPOINT.sm} {
        min-height: 504px;
        margin-bottom: 24px;
        padding: 40px;
    }

    ${BREAKPOINT.lg} {
        min-height: 367px;
    }
`;

const StyledProjectTitle = styled.p`
    margin: 0;
    margin-bottom: 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.28;

    ${BREAKPOINT.sm} {
        margin-bottom: 24px;
        font-size: 32px;
    }
`;

const StyledDomenName = styled.span`
    margin-bottom: 16px;
    color: ${COLORS.dark};
    font-weight: 400;
    font-size: 14px;
    line-height: 1.28;

    ${BREAKPOINT.sm} {
        margin-bottom: 24px;
    }
`;

const StyledSubtitle = styled.p`
    margin: 0;
    margin: 0 0 4px;
    color: ${COLORS.dark};
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 1.28;

    ${BREAKPOINT.sm} {
        font-size: 18px;
    }
`;

const StyledDescription = styled.p`
    display: none;

    ${BREAKPOINT.sm} {
        display: block;
        margin: 0 0 24px;
        color: rgb(56 70 70 / 50%);
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        line-height: 18px;
    }
`;

const StyledFigureBlock = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: auto;
`;

const StyledFigureContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 50%;
`;

const StyledFigureTitle = styled.span`
    color: ${COLORS.dark};
    font-weight: 700;
    font-size: 16px;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        font-size: 24px;
    }
`;

const StyledFigureSubitle = styled.span`
    color: rgba(56 70 70 / 50%);
    font-weight: 400;
    font-size: 10px;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        font-size: 12px;
    }
`;
