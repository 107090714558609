// libraries
import React from 'react';
import styled from 'styled-components';
// components
import { BREAKPOINT } from 'components/UI/Grid';
// constants
import { COLORS } from 'components/UI/Theme';

export const WhyUsPoint = ({ item }) => {
    return (
        <StyledWhyUsPoint>
            <StyledPointTitle>{item.title}</StyledPointTitle>
            <StyledPointSubtitle>{item.subtitle}</StyledPointSubtitle>
        </StyledWhyUsPoint>
    );
};

const StyledWhyUsPoint = styled.li`
    &:not(:last-child) {
        position: relative;
        margin-bottom: 12px;
        padding-bottom: 12px;

        ${BREAKPOINT.md} {
            margin-bottom: 32px;
            padding-bottom: 32px;
        }

        &::after {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 91px;
            height: 1px;
            background: ${COLORS.primary};
            content: '';
        }
    }
`;

const StyledPointTitle = styled.p`
    margin: 0;
    margin-bottom: 8px;
    font-weight: 700;
    font-size: 18px;
    font-style: normal;
    line-height: 1.28;
`;

const StyledPointSubtitle = styled.span`
    color: rgba(56 70 70 / 50%);
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 1.28;
`;
