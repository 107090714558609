// static
import effectiveImage from 'images/common/effective.jpg';

export const DATA = {
    title: 'Почему наша<br/>команда?',
    description:
        'Мы специализируемся на<b> кредитном скоринге, Data Science и финансовых цифровых технологиях</b>. Основным направлением работы IDF Technology является разработка Enterprise-решений в финтех-секторе для международных проектов, действующих в <b>России и Казахстане</b>.',
    inform: [
        {
            id: 1,
            title: 'Точно знаем, что делаем',
            subtitle: '9 бизнесов в 5 странах,  более 50 проектов за 10 лет',
        },
        {
            id: 2,
            title: 'Полная прозрачность процесса',
            subtitle: 'без скрытых доплат и запутанных планов',
        },
        {
            id: 3,
            title: 'Никакой бюрократии',
            subtitle: 'мы сосредоточены на скорости и продуктивности',
        },
        {
            id: 4,
            title: 'Экономическая эффективность',
            subtitle: 'проанализируем вашу идею и сократим затраты на нее',
        },
        {
            id: 5,
            title: 'Нам важен ваш комфорт',
            subtitle: 'вы сами выбираете модель взаимодействия с нами',
        },
    ],
    statistic: [
        {
            id: 1,
            title: '150+',
            subtitle: 'Разработчиков',
        },
        {
            id: 2,
            title: '25+',
            subtitle: 'Технологий',
        },
        {
            id: 3,
            title: '40+',
            subtitle: 'Бизнес аналитиков ',
        },
        {
            id: 4,
            title: '10+',
            subtitle: 'Направлений',
        },
    ],
    quote: {
        label: 'Наш опыт и навыки для решения ваших проблем',
        image: effectiveImage,
        text: 'Пока вы добиваетесь поставленных целей, мы уже заботимся об их технической реализации<p>Мы эффективно справляемся с поставленными задачами в сферах</p>',
        subtext: '<span>B2B</span><span>B2C</span><span>B2B2C</span>',
    },
};
