// libraries
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
// components
import Text from 'components/UI/Text';
import Title from 'components/UI/Title';
import { BREAKPOINT, Container, SCREENSIZE } from 'components/UI/Grid';
import { Quote } from 'components/Blocks/Quote';
import { WhyUsPoint } from 'components/Blocks/WhyUs/WhyUsPoint';
// constants
import { COLORS } from 'components/UI/Theme';
import { Projects } from 'components/Blocks/Projects';
import { DATA } from 'components/Blocks/WhyUs/data';
// hooks
import { useViewport } from 'utils/hooks/useViewport';
// static
import { ContentLogo } from 'icons';

export const WhyUs = () => {
    const { title, description, inform, statistic, quote } = DATA;
    const { width } = useViewport();

    return (
        <StyledWhyUsBlock id="why-us">
            <Container>
                <StyledInfo>
                    <StaticImage
                        src="../../../images/common/special.png"
                        className="styled-info-image"
                        alt="Разрабатываем enterprise-решения для финтех-компаний"
                        loading="lazy"
                        width={435}
                        height={472}
                    />
                    <StyledContent>
                        <ContentLogo
                            width={width > SCREENSIZE.sm ? '358' : null}
                            height={width > SCREENSIZE.sm ? '38' : null}
                            styles={{ margin: 'auto' }}
                        />
                        <StyledTextContent variant="text1" dangerouslySetInnerHTML={{ __html: description }} />
                    </StyledContent>
                </StyledInfo>
                <Projects />
                <StlyedWhyContent>
                    <StyledTitle as="h2" variant="h2" dangerouslySetInnerHTML={{ __html: title }} />
                    <StyledPoints>
                        {inform && inform.length && inform.map((item) => <WhyUsPoint item={item} key={item.id} />)}
                    </StyledPoints>
                    <StaticImage
                        src="../../../images/common/employes.png"
                        alt="Опытные сотрудники для развития вашего бизнеса"
                        loading="lazy"
                        className="why-us-bottom-image"
                        width={412}
                        height={192}
                        objectFit="contain"
                    />
                    <StyledContentInfo>
                        <StaticImage
                            src="../../../images/common/working.png"
                            className="why-us-top-image"
                            alt="Наши компетенции для развития вашего бизнеса"
                            loading="lazy"
                            width={298}
                            height={359}
                        />
                        {statistic &&
                            statistic.length &&
                            statistic.map((item) => (
                                <StyledStatisticPoint key={`${item.id}_statistic`}>
                                    <StyledStatisticTitle>{item.title}</StyledStatisticTitle>
                                    <StyledStatisticSubitle>{item.subtitle}</StyledStatisticSubitle>
                                </StyledStatisticPoint>
                            ))}
                    </StyledContentInfo>
                </StlyedWhyContent>
            </Container>

            <StyledOrderBlock>
                <Container>
                    <Quote
                        logo={
                            <ContentLogo
                                width={width > SCREENSIZE.sm ? '300' : '192'}
                                height={width > SCREENSIZE.sm ? '32' : '20'}
                            />
                        }
                        sideText="right"
                        image={quote.image}
                        imageAlt={quote.label}
                        text={quote.text}
                        subtext={quote.subtext}
                    />
                </Container>
            </StyledOrderBlock>
        </StyledWhyUsBlock>
    );
};

const StyledWhyUsBlock = styled.section`
    padding: 24px 0 0;
    background-color: ${COLORS.dark};
`;

const StyledInfo = styled.div`
    position: relative;
    max-width: 794px;
    margin: -100px auto 56px;
    color: #fff;
    border: 1px solid rgb(59 172 255 / 50%);
    border-radius: 40px;

    ${BREAKPOINT.sm} {
        margin: -120px 0 96px auto;
    }

    ${BREAKPOINT.lg} {
        margin: -200px 0 96px auto;
    }

    .styled-info-image {
        display: none;

        ${BREAKPOINT.lg} {
            position: absolute;
            top: 2%;
            left: -15%;
            display: block;
            margin-left: auto;
        }
    }
`;

const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 16px;

    ${BREAKPOINT.sm} {
        padding: 40px;
    }

    ${BREAKPOINT.lg} {
        max-width: 435px;
        margin-left: auto;
        padding: 80px 40px 80px 0;
    }
`;

const StyledTextContent = styled(Text)`
    margin-top: 32px;
    font-weight: 400;
    font-style: normal;

    ${BREAKPOINT.sm} {
        font-size: 24px;
    }

    ${BREAKPOINT.lg} {
        margin-top: 80px;
    }
`;

const StlyedWhyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 100%;
    margin-bottom: 40px;
    padding: 20px;
    background: #fff;
    border-radius: 20px;

    ${BREAKPOINT.md} {
        flex-flow: column wrap;
        justify-content: space-between;
        height: 1090px;
        margin-bottom: 120px;
        padding: 40px;
    }

    ${BREAKPOINT.lg} {
        padding: 80px;
    }

    .why-us-bottom-image {
        margin: 20px auto;

        ${BREAKPOINT.sm} {
            width: 100%;
        }

        ${BREAKPOINT.md} {
            width: auto;
            max-width: 45%;
            margin: 0;
        }

        ${BREAKPOINT.lg} {
            max-width: 100%;
        }
    }
`;

const StyledTitle = styled(Title)`
    margin: 0;
    margin-bottom: 40px;
    font-weight: 400;
    font-size: 28px;
    font-style: normal;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        margin-bottom: 33px;
        font-size: 40px;
    }

    ${BREAKPOINT.md} {
        margin-bottom: 0;
    }
`;

const StyledPoints = styled.ul`
    margin: 0;
    padding: 0;

    ${BREAKPOINT.md} {
        max-width: 45%;
    }

    ${BREAKPOINT.lg} {
        max-width: 100%;
    }
`;

const StyledContentInfo = styled.div`
    display: flex;
    flex-flow: row wrap;
    flex-grow: 1;
    justify-content: space-between;

    ${BREAKPOINT.sm} {
        width: auto;
        margin: auto;
    }

    ${BREAKPOINT.md} {
        flex-direction: column;
        max-height: 100%;
        margin: 0;
    }

    .why-us-bottom-image {
        display: none;

        ${BREAKPOINT.md} {
            display: block;
        }
    }
`;

const StyledStatisticPoint = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    max-width: 46%;
    margin: 10px 0;
    padding: 16px;
    background: ${COLORS.primary};
    border-radius: 20px;

    ${BREAKPOINT.md} {
        max-width: 100%;
        margin: 0;
    }
`;

const StyledStatisticTitle = styled.p`
    margin: 0;
    color: #fff;
    font-weight: 400;
    font-size: 24px;
    font-style: normal;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        display: block;
        font-size: 56px;
    }
`;

const StyledStatisticSubitle = styled.span`
    color: rgba(255 255 255 / 70%);
    font-weight: 400;
    font-size: 12px;
    font-style: normal;
    line-height: 1.28;

    ${BREAKPOINT.sm} {
        font-size: 18px;
    }
`;

const StyledOrderBlock = styled.div`
    padding: 40px 0;
    background: ${COLORS.primary};

    ${BREAKPOINT.lg} {
        padding: 120px 0;
    }
`;
