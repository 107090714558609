// libraries
import React from 'react';
import styled, { css } from 'styled-components';
// components
import Typography from 'components/UI/Core/Typography';
// constants
import { BREAKPOINT } from 'components/UI/Grid';

const Text = ({ children, variant, ...props }) => {
    return (
        <StyledTitle variant={variant} {...props}>
            {children}
        </StyledTitle>
    );
};

export default Text;

const StyledTitle = styled(Typography)`
    font-weight: 400;
    font-style: normal;
    line-height: 1.4;
    ${({ variant }) => {
        switch (variant) {
            case 'text1':
                return css`
                    font-size: 16px;
                    ${BREAKPOINT.md} {
                        font-size: 24px;
                    }
                `;
            case 'text2':
                return css`
                    font-size: 13px;
                    ${BREAKPOINT.md} {
                        font-size: 16px;
                    }
                `;
            case 'text3':
                return css`
                    font-size: 11px;
                    ${BREAKPOINT.md} {
                        font-size: 13px;
                    }
                `;
            default:
                return css`
                    font-size: 25px;
                    ${BREAKPOINT.lg} {
                        font-size: 40px;
                    }
                `;
        }
    }};
`;
