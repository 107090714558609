// libraries
import React from 'react';
import styled from 'styled-components';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css/core';
// components
import { BREAKPOINT, Col, Container, Row, SCREENSIZE } from 'components/UI/Grid';
import Title from 'components/UI/Title';
import { SliderPagination } from 'components/UI/SliderPagination';
// constants
import { DATA } from 'components/Blocks/Technology/data';

export const Technology = () => {
    const { title, arrayTechnology } = DATA;

    const sliderOptions = {
        arrows: false,
        gap: 16,
        fixedWidth: 240,
        mediaQuery: 'min',
        grid: {
            dimensions: [
                [2, 1],
                [2, 1],
                [2, 1],
                [2, 1],
            ],
            gap: {
                row: 16,
            },
        },
        breakpoints: {
            [SCREENSIZE.md]: {
                fixedWidth: 298,
            },
        },
    };

    return (
        <StyledTechnology id="technology">
            <StyledContainer>
                <StyledStackTitle as="h2" variant="h2" dangerouslySetInnerHTML={{ __html: title }} />
                <StyledMobileSlider
                    hasTrack={false}
                    options={sliderOptions}
                    extensions={{ Grid }}
                    aria-label="technology"
                >
                    <SplideTrack>
                        {arrayTechnology.map((rows) =>
                            rows.map(({ items }, index) => (
                                <SplideSlide key={index}>
                                    <StyledGroup>
                                        {items.map(({ icon, name }, itemIndex) => (
                                            <StyledItem key={itemIndex}>
                                                <img src={icon} alt={name} width="30" height="20" />
                                                {name}
                                            </StyledItem>
                                        ))}
                                    </StyledGroup>
                                </SplideSlide>
                            ))
                        )}
                    </SplideTrack>
                    <SliderPagination />
                </StyledMobileSlider>
                <StyledDesktopGrid>
                    {arrayTechnology.map((rows, index) => (
                        <Col key={index}>
                            {rows.map(({ items }, groupIndex) => (
                                <StyledGroup key={groupIndex}>
                                    {items.map(({ icon, name }, itemIndex) => (
                                        <StyledItem key={itemIndex}>
                                            <img src={icon} alt={name} width="30" height="20" />
                                            {name}
                                        </StyledItem>
                                    ))}
                                </StyledGroup>
                            ))}
                        </Col>
                    ))}
                </StyledDesktopGrid>
            </StyledContainer>
        </StyledTechnology>
    );
};

const StyledTechnology = styled.section`
    padding: 30px 0 150px;
    overflow: hidden;

    ${BREAKPOINT.lg} {
        padding: 60px 0 300px;
    }
`;

const StyledStackTitle = styled(Title)`
    width: 100%;
    margin: 0 var(--gutter) 16px;
    font-weight: 400;
    font-size: 28px;
    font-style: normal;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        font-size: 40px;
    }

    ${BREAKPOINT.lg} {
        position: absolute;
        height: auto;
        min-height: 104px;
    }
`;

const StyledGroup = styled.ul`
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 24px 32px;
    list-style: none;
    background: #fff;
    border-radius: 20px;

    ${BREAKPOINT.lg} {
        height: initial;
        margin-top: 0;
        margin-bottom: 16px;
    }
`;

const StyledContainer = styled(Container)`
    position: relative;
`;

const StyledMobileSlider = styled(Splide)`
    ${BREAKPOINT.lg} {
        display: none;
    }

    .splide__track {
        ${BREAKPOINT.md} {
            margin-bottom: 26px;
            padding: 0;
        }
    }

    .splide__slide {
        display: flex;
        flex-direction: column;
    }

    .splide__slide__row {
        flex-grow: 1;
        height: initial !important;
    }
`;

const StyledDesktopGrid = styled(Row)`
    display: none;

    ${BREAKPOINT.lg} {
        --gutter: 8px;

        display: flex;
    }

    ${Col} {
        display: flex;
        flex-direction: column;
        > ${StyledGroup}:first-child {
            margin-top: auto;
        }
    }
`;

const StyledItem = styled.li`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 24px;
    font-weight: 300;
    font-size: 24px;
    font-family: Outfit, sans-serif;
    font-style: normal;

    &:last-child {
        margin-bottom: 0;
    }

    > img {
        width: 1.2em;
        max-height: 2em;
        margin-right: 0.8em;
    }
`;
