// libraries
import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import styled from 'styled-components';
// components
import { BREAKPOINT, Container } from 'components/UI/Grid';
import { Form } from 'components/Blocks/FeedbackForm/Form';
import { FormInfo } from 'components/Blocks/FeedbackForm/FormInfo';
import Title from 'components/UI/Title';
// constants
import { COLORS } from 'components/UI/Theme';
// static
import data from 'components/data.json';

export const FeedbackForm = () => {
    const {
        contacts: { formTitle, feedbackFormTextBtn },
    } = data;

    return (
        <StyledFormSection>
            <Container>
                <StyledFormPanel>
                    <StyledFormBlock>
                        <StyledFormTitle
                            forwardedAs="h2"
                            variant="h2"
                            dangerouslySetInnerHTML={{ __html: formTitle }}
                        />
                        <Form textBtn={feedbackFormTextBtn} />
                    </StyledFormBlock>
                    <StyledInfoBlock>
                        <StaticImage 
                            src="../../../images/common/workprocess.png"
                            className="form-image"
                            alt="Напишите нам: контакты для связи"
                            loading="lazy"
                            width={435}
                            height={370}
                        />
                        <FormInfo />
                    </StyledInfoBlock>
                </StyledFormPanel>
            </Container>
        </StyledFormSection>
    );
};

const StyledFormBlock = styled.div`
    padding: 20px;
    background: #fff;
    border-radius: 40px;

    ${BREAKPOINT.md} {
        padding: 40px;
    }

    ${BREAKPOINT.lg} {
        grid-row: 1/2;
        grid-column: 1/3;
        padding: 24px calc(313px + 54px) 98px 54px;
    }
`;

const StyledFormTitle = styled(Title)`
    margin-top: 20px;
    color: ${COLORS.primary};
    font-weight: 400;

    ${BREAKPOINT.sm} {
        font-size: 40px;
    }

    ${BREAKPOINT.lg} {
        grid-row: 1/2;
        grid-column: 1/3;
        margin-top: 80px;
    }
`;

const StyledFormSection = styled.section`
    padding: 40px 0;

    ${BREAKPOINT.sm} {
        padding: 80px 0;
    }

    ${BREAKPOINT.md} {
        padding: 96px 0;
    }
`;

const StyledFormPanel = styled.div`
    position: relative;
    display: grid;
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 20px;

    ${BREAKPOINT.lg} {
        grid-template-columns: auto 313px 122px;
        max-width: 926px;
        background-color: transparent;
        border-radius: 40px;
    }
`;

const StyledInfoBlock = styled.div`
    overflow: hidden;
    background-color: ${COLORS.darkGrey};
    border-radius: 0 0 20px 20px;
    box-shadow: 0 2px 20px rgb(0 0 0 / 10%);

    ${BREAKPOINT.lg} {
        grid-row: 1/2;
        grid-column: 2/4;
        max-height: 536px;
        margin: 24px 0 98px;
        border-radius: 40px;
    }

    .form-image {
        display: none;

        ${BREAKPOINT.lg} {
            display: block;
        }
    }
`;
