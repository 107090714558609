export const DATA = {
    title: 'Направления',
    arrayDirections: [
        {
            type: 'full',
            items: [
                [
                    {
                        id: 1,
                        info: {
                            icon: 'directions/application.svg',
                            title: 'Разработка мобильных приложений',
                            subtitle: 'Mobile applications development',
                        },
                    },

                    {
                        id: 2,
                        info: {
                            icon: 'directions/integration.svg',
                            title: 'Сервисная платформенная интеграция',
                            subtitle: 'Service and platform integration',
                        },
                    },

                    {
                        id: 3,
                        info: {
                            icon: 'directions/analytics.svg',
                            title: 'Бизнес-аналитика',
                            subtitle: 'Business analysis',
                        },
                    },
                    {
                        id: 4,
                        info: {
                            icon: 'directions/lock.svg',
                            title: 'Безопасность',
                            subtitle: 'Cyber security',
                        },
                    },
                ],
                [
                    {
                        id: 5,
                        info: {
                            icon: 'directions/web-software.svg',
                            title: 'Разработка<br>веб-приложений',
                            subtitle: 'Web-applications development',
                        },
                    },

                    {
                        id: 6,
                        info: {
                            icon: 'directions/devops.svg',
                            title: 'Разработка программного обеспечения',
                            subtitle: 'Software development',
                        },
                    },
                    {
                        id: 7,
                        info: {
                            icon: 'directions/support.svg',
                            title: 'Техническая поддержка',
                            subtitle: 'Technical support',
                        },
                    },
                    {
                        id: 8,
                        info: {
                            icon: 'directions/quality.svg',
                            title: 'Обеспечение качества',
                            subtitle: 'Quality assurance',
                        },
                    },
                ],
            ],
        },
        {
            type: 'statistyc',
            items: [
                {
                    id: 9,
                    info: {
                        title: '100+',
                        subtitle: 'Back-end developers',
                    },
                },
                {
                    id: 10,
                    info: {
                        title: '15+',
                        subtitle: 'Android developers',
                    },
                },
                {
                    id: 11,
                    info: {
                        title: '25+',
                        subtitle: 'Front-end developers',
                    },
                },
                {
                    id: 12,
                    info: {
                        title: '50+',
                        subtitle: 'DevOps and System administrators',
                    },
                },
                {
                    id: 13,
                    info: {
                        title: '40+',
                        subtitle: 'Business analysts',
                    },
                },
            ],
        },
        {
            type: 'some',
            items: [
                {
                    id: 14,
                    info: {
                        directionMobile: 'row',
                        icon: 'directions/write-documentation.svg',
                        title: 'DevOps',
                        subtitle: '',
                    },
                },
                {
                    id: 15,
                    info: {
                        directionMobile: 'row',
                        icon: 'directions/calendar.svg',
                        title: 'Project management',
                        subtitle: '',
                    },
                },

                {
                    id: 16,
                    info: {
                        directionMobile: 'row',
                        icon: 'directions/bank.svg',
                        title: 'RnD',
                        subtitle: '',
                    },
                },
            ],
        },
    ],
};
