// libraries
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
// components
import { BREAKPOINT, Col, Container, Row, SCREENSIZE } from 'components/UI/Grid';
import Text from 'components/UI/Text';
// hooks
import { useViewport } from 'utils/hooks/useViewport';
// constants
import { COLORS } from 'components/UI/Theme';
import { ContentLogo } from 'icons';
import { DATA } from 'components/Blocks/About/data';

export const About = () => {
    const { description } = DATA;
    const { width } = useViewport();

    return (
        <StyledAbout id="about-us">
            <Container>
                <StyledInfo>
                    <StyledContentImage xs={6} lg={3}>
                        <StaticImage
                            src="../../../images/common/solution.png"
                            alt="Создание и интеграция продуктов для банков и МФО"
                            loading="lazy"
                            width={435}
                            height={472}
                        />
                    </StyledContentImage>
                    <StyledContent xs={6} lg={3}>
                        <ContentLogo
                            isWhite
                            width={width > SCREENSIZE.sm ? '435' : '272'}
                            height={width > SCREENSIZE.sm ? '46' : '28'}
                        />
                        <StyledTextContent variant="text1" dangerouslySetInnerHTML={{ __html: description }} />
                    </StyledContent>
                </StyledInfo>
            </Container>
        </StyledAbout>
    );
};

const StyledAbout = styled.section`
    background-color: ${COLORS.primary};

    ${BREAKPOINT.lg} {
        padding: 120px 0 0;
    }
`;

const StyledInfo = styled(Row)`
    position: relative;
    color: #fff;
    border-radius: 40px;
`;

const StyledContent = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 0;

    ${BREAKPOINT.sm} {
        padding: 80px 0;
    }

    ${BREAKPOINT.lg} {
        margin-left: auto;
        padding: 60px 0;
    }
`;

const StyledContentImage = styled(Col)`
    display: none;

    ${BREAKPOINT.lg} {
        display: block;
    }
`;

const StyledTextContent = styled(Text)`
    margin-top: 40px;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.28;

    ${BREAKPOINT.lg} {
        margin-top: 40px;
    }

    ul {
        margin: 0;
        list-style-type: disc;
    }

    p {
        margin: 0;

        &:first-child {
            margin-bottom: 18px;
        }
    }
`;
