export const DATA = {
    title: 'Технологии',
    arrayTechnology: [
        [
            {
                items: [
                    { icon: 'technology/aws.svg', name: 'AWS' },
                    { icon: 'technology/docker.svg', name: 'Docker' },
                ],
            },
            {
                items: [
                    { icon: 'technology/nginx.svg', name: 'Nginx' },
                    { icon: 'technology/consul.svg', name: 'Consul' },
                    { icon: 'technology/haproxy.svg', name: 'Haproxy' },
                    { icon: 'technology/vault.svg', name: 'Vault' },
                    { icon: 'technology/nomad.svg', name: 'Nomad' },
                    { icon: 'technology/zabbix.svg', name: 'Zabbix' },
                    { icon: 'technology/grafana.svg', name: 'Grafana' },
                    { icon: 'technology/prometheus.svg', name: 'Prometheus' },
                ],
            },
        ],
        [
            {
                items: [
                    { icon: 'technology/kotlin.svg', name: 'Kotlin' },
                    { icon: 'technology/java.svg', name: 'Java' },
                    { icon: 'technology/react.svg', name: 'React' },
                    { icon: 'technology/flutter.svg', name: 'Flutter' },
                    { icon: 'technology/android.svg', name: 'Android' },
                    { icon: 'technology/wordpress.svg', name: 'WordPress' },
                ],
            },
            {
                items: [
                    { icon: 'technology/rabbitmq.svg', name: 'RabbitMQ' },
                    { icon: 'technology/kafka.svg', name: 'Kafka' },
                ],
            },
            {
                items: [
                    { icon: 'technology/glassfish.svg', name: 'GlassFish' },
                    { icon: 'technology/tomcat.svg', name: 'Tomcat' },
                    { icon: 'technology/netty.svg', name: 'Netty' },
                ],
            },
        ],
        [
            {
                items: [
                    { icon: 'technology/jenkins.svg', name: 'Jenkins' },
                    { icon: 'technology/sonarqube.svg', name: 'SonarQube' },
                    { icon: 'technology/gradle.svg', name: 'Gradle' },
                ],
            },
            {
                items: [
                    { icon: 'technology/mongodb.svg', name: 'MongoDB' },
                    { icon: 'technology/mysql.svg', name: 'MySQL' },
                    { icon: 'technology/clickhouse.svg', name: 'ClickHouse' },
                ],
            },
            {
                items: [
                    { icon: 'technology/spring.svg', name: 'Spring' },
                    { icon: 'technology/hibernate.svg', name: 'Hibernate' },
                    { icon: 'technology/jooq.svg', name: 'JOOQ' },
                    { icon: 'technology/redis.svg', name: 'Redis' },
                    { icon: 'technology/elastic.svg', name: 'Elastic' },
                ],
            },
        ],
    ],
};
