// libraries
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
// constants
import { BREAKPOINT } from 'components/UI/Grid';
import { COLORS } from 'components/UI/Theme';

export const Quote = ({ logo, sideText, text, subtext }) => {
    return (
        <StyledQuote sideText={sideText}>
            <StyledQouteInfo sideText={sideText}>
                {logo}
                <StyledOquteText dangerouslySetInnerHTML={{ __html: text }} />
                {subtext && <StyledSubtext dangerouslySetInnerHTML={{ __html: subtext }} />}
            </StyledQouteInfo>
            <StaticImage
                src="../../../images/common/effective.jpg"
                alt="Наш опыт и навыки для решения ваших проблем"
                loading="lazy"
                className="quote-image"
                width={435}
                height={582}
            />
        </StyledQuote>
    );
};

const StyledQuote = styled.div`
    display: flex;
    margin: auto;
    padding: 40px 24px;
    background: #fff;
    border: 1px solid rgba(59 172 255 / 50%);
    border-radius: 40px;

    ${BREAKPOINT.sm} {
        padding: 40px;
    }

    ${BREAKPOINT.lg} {
        max-width: 92.5%;
        padding: 24px 80px;

        ${({ sideText }) =>
            sideText === 'right'
                ? css`
                      margin-right: -(var(--gutter));
                      margin-left: auto;
                  `
                : css`
                      margin-right: auto;
                      margin-left: -(var(--gutter));
                  `};
    }

    .quote-image {
        display: none;
        border-radius: 40px;

        ${BREAKPOINT.lg} {
            display: block;
            flex-shrink: 0;
            max-width: 100%;
            ${({ sideText }) =>
                sideText === 'right'
                    ? css`
                          order: 1;
                          margin-left: -25%;
                      `
                    : css`
                          order: 2;
                          margin-right: -25%;
                      `};
        }
    }
`;

const StyledQouteInfo = styled.div`
    ${({ sideText }) =>
        sideText === 'right'
            ? css`
                  order: 2;
                  margin: auto;
                  text-align: center;

                  ${BREAKPOINT.lg} {
                      width: 100%;
                      margin-left: auto;
                      padding: 80px 0 80px 56px;
                      text-align: left;
                  }
              `
            : css`
                  order: 1;
                  width: 100%;
                  padding: 0;
                  text-align: center;

                  ${BREAKPOINT.lg} {
                      width: 60%;
                      max-width: 435px;
                      padding: 80px 56px 80px 0;
                      text-align: left;
                  }
              `};
`;

const StyledOquteText = styled.div`
    margin-top: 20px;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        max-width: 255px;
        margin: 24px auto 0;
        font-size: 20px;
        text-align: center;
    }

    ${BREAKPOINT.sm} {
        max-width: 95%;
        margin: 24px auto;
        font-size: 20px;
        text-align: center;
    }

    ${BREAKPOINT.lg} {
        margin: 40px 0 24px;
        font-size: 24px;
        text-align: left;
    }
`;
const StyledSubtext = styled.div`
    display: flex;
    justify-content: space-between;
    margin: auto;

    ${BREAKPOINT.sm} {
        max-width: 80%;
    }

    ${BREAKPOINT.sm} {
        max-width: 100%;
    }

    span {
        color: ${COLORS.primary};
        font-weight: 400;
        font-size: 24px;
        line-height: 1.2;

        ${BREAKPOINT.sm} {
            font-size: 40px;
        }
    }
`;
