// libraries
import React from 'react';
import styled from 'styled-components';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css/core';
// components
import { BREAKPOINT, Col, Row, SCREENSIZE } from 'components/UI/Grid';
import Title from 'components/UI/Title';
import { ProjectItem } from 'components/Blocks/Projects/ProjectItem';
import { SliderPagination } from 'components/UI/SliderPagination';
// constants
import { DATA } from 'components/Blocks/Projects/data';

export const Projects = () => {
    const { title, arrayProjects } = DATA;

    const sliderOptions = {
        arrows: false,
        gap: 16,
        fixedWidth: 240,
        padding: { left: 'var(--mobile-padding)', right: 'var(--mobile-padding)' },
        mediaQuery: 'min',
        grid: {
            dimensions: [
                [1, 1],
                [1, 1],
                [1, 1],
                [1, 1],
            ],
            gap: {
                row: 16,
            },
        },
        breakpoints: {
            [SCREENSIZE.sm]: {
                fixedWidth: 298,
            },
        },
    };

    return (
        <StyledProjects id="our-projects">
            <StyledTitle as="h2" dangerouslySetInnerHTML={{ __html: title }} />
            <StyledMobileSlider hasTrack={false} options={sliderOptions} extensions={{ Grid }} aria-label="technology">
                <SplideTrack>
                    {arrayProjects.map(({ id, title: projectTitle, domen, subtitle, description, since, info }) => (
                        <SplideSlide key={id}>
                            <ProjectItem
                                id={id}
                                title={projectTitle}
                                domen={domen}
                                subtitle={subtitle}
                                description={description}
                                since={since}
                                info={info}
                                xs={6}
                                md={3}
                            />
                        </SplideSlide>
                    ))}
                </SplideTrack>
                <StyledSliderPagination />
            </StyledMobileSlider>
            <StyledDesktopGrid>
                {arrayProjects.map(({ id, title: projectTitle, domen, subtitle, description, since, info }) => (
                    <Col key={id} xs={6} md={3}>
                        <ProjectItem
                            id={id}
                            title={projectTitle}
                            domen={domen}
                            subtitle={subtitle}
                            description={description}
                            since={since}
                            info={info}
                        />
                    </Col>
                ))}
            </StyledDesktopGrid>
        </StyledProjects>
    );
};

const StyledMobileSlider = styled(Splide)`
    margin-bottom: 80px;

    ${BREAKPOINT.lg} {
        display: none;
    }

    .splide__track {
        margin: 0 calc(var(--mobile-padding) * -1);

        ${BREAKPOINT.md} {
            margin-bottom: 26px;
            padding: 0;
        }
    }

    .splide__slide {
        display: flex;
        flex-direction: column;
    }

    .splide__slide__row {
        flex-grow: 1;
        height: initial !important;
    }
`;

const StyledSliderPagination = styled(SliderPagination)`
    li > button {
        background-color: #fff;

        &.is-active {
            background-color: #fff;
        }
    }
`;

const StyledProjects = styled.div`
    ${BREAKPOINT.lg} {
        padding-bottom: 120px;
    }
`;

const StyledDesktopGrid = styled(Row)`
    display: none;

    ${BREAKPOINT.lg} {
        display: flex;
    }
`;

const StyledTitle = styled(Title)`
    margin: 0;
    margin-bottom: 32px;
    color: #fff;
    font-weight: 400;
    font-size: 28px;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        font-size: 40px;
    }
`;
