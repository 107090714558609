// libraries
import React from 'react';
import styled from 'styled-components';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';
import { Grid } from '@splidejs/splide-extension-grid';
// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css/core';
// constants
import { COLORS } from 'components/UI/Theme';
// components
import { BREAKPOINT, Col, Container, Row, SCREENSIZE } from 'components/UI/Grid';
import { SliderPagination } from 'components/UI/SliderPagination';
import Title from 'components/UI/Title';
// constants
import { DATA } from 'components/Blocks/Directions/data';

export const Directions = () => {
    const { title, arrayDirections } = DATA;
    const fullItems = arrayDirections.filter((item) => item.type === 'full')[0].items;
    const statistyctItems = arrayDirections.filter((item) => item.type === 'statistyc')[0].items;
    const someItems = arrayDirections.filter((item) => item.type === 'some')[0].items;
    const mobileItems = [...fullItems, [...someItems]];

    const sliderOptions = {
        arrows: false,
        gap: 16,
        fixedWidth: 240,
        mediaQuery: 'min',
        grid: {
            dimensions: [
                [2, 1],
                [2, 1],
                [2, 1],
                [2, 1],
                // eslint-disable-next-line no-magic-numbers
                [4, 1],
            ],
            gap: {
                row: 16,
            },
        },
        breakpoints: {
            [SCREENSIZE.md]: {
                fixedWidth: 298,
            },
        },
    };

    return (
        <StyledDirectionsBlock id="directions">
            <StyledContainer>
                <StyledTitle as="h2" variant="h2" dangerouslySetInnerHTML={{ __html: title }} />
                <StyledMobileSlider
                    hasTrack={false}
                    options={sliderOptions}
                    extensions={{ Grid }}
                    aria-label="directions"
                >
                    <SplideTrack>
                        {mobileItems.map((rows) =>
                            rows.map(({ id, info }) => (
                                <SplideSlide key={id}>
                                    <StyledFullContent
                                        direction={info.directionMobile ? info.directionMobile : 'column'}
                                        key={id}
                                    >
                                        <img src={info.icon} alt={info.title} width="56" height="56" />
                                        <StyledCardTitle dangerouslySetInnerHTML={{ __html: info.title }} />
                                        <span>{info.subtitle}</span>
                                    </StyledFullContent>
                                </SplideSlide>
                            ))
                        )}
                    </SplideTrack>
                    <SliderPagination />
                    <div>
                        <StyledMobileGrid>
                            <StyledPeopleIcon src="directions/people.svg" alt="people" width="134" height="73" />
                            {statistyctItems.map(({ id, info }) => (
                                <Col key={id} xs={6} sm={3}>
                                    <StyledStatistycContent key={id}>
                                        <StyledBigNumber as="p">{info.title}</StyledBigNumber>
                                        <span>{info.subtitle}</span>
                                    </StyledStatistycContent>
                                </Col>
                            ))}
                        </StyledMobileGrid>
                    </div>
                </StyledMobileSlider>
                <div>
                    <StyledDesktopGrid>
                        {fullItems.map((rows, index) => (
                            <Col lg={2} key={index}>
                                {rows.map(({ id, info }) => (
                                    <StyledFullContent key={id}>
                                        <img src={info.icon} alt={info.title} width="56" height="56" />
                                        <StyledCardTitle dangerouslySetInnerHTML={{ __html: info.title }} />
                                        <span>{info.subtitle}</span>
                                    </StyledFullContent>
                                ))}
                            </Col>
                        ))}
                        <Col lg={2}>
                            {statistyctItems.map(({ id, info }) => (
                                <StyledStatistycContent key={id}>
                                    <StyledBigNumber as="p">{info.title}</StyledBigNumber>
                                    <span>{info.subtitle}</span>
                                </StyledStatistycContent>
                            ))}
                        </Col>
                    </StyledDesktopGrid>
                    <StyledSomeDesktopGrid>
                        {someItems.map(({ id, info }) => (
                            <Col key={id} lg={2}>
                                <StyledFullContent>
                                    <img src={info.icon} alt={info.title} width="56" height="56" />
                                    <StyledCardTitle dangerouslySetInnerHTML={{ __html: info.title }} />
                                    <span>{info.subtitle}</span>
                                </StyledFullContent>
                            </Col>
                        ))}
                    </StyledSomeDesktopGrid>
                </div>
            </StyledContainer>
        </StyledDirectionsBlock>
    );
};

const StyledMobileGrid = styled(Row)`
    ${BREAKPOINT.sm} {
        margin-top: 32px;
    }

    ${BREAKPOINT.lg} {
        --gutter: 8px;

        display: flex;
        margin-top: 0;
    }

    ${Col}:not(:last-child) {
        margin-bottom: 32px;
    }
`;

const StyledDirectionsBlock = styled.section`
    padding: 30px 0;
    overflow: hidden;

    ${BREAKPOINT.sm} {
        padding: 80px 0;
        padding-top: 120px;
        padding-bottom: 60px;
    }

    ${BREAKPOINT.md} {
        padding-top: 120px;
        padding-bottom: 60px;
    }
`;

const StyledPeopleIcon = styled.img`
    margin: 32px auto;

    ${BREAKPOINT.lg} {
        display: none;
    }
`;

const StyledContainer = styled(Container)`
    position: relative;
`;

const StyledTitle = styled(Title)`
    position: relative;
    margin: 0;
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 28px;
    font-style: normal;
    line-height: 1.3;

    ${BREAKPOINT.sm} {
        font-size: 40px;
    }

    ${BREAKPOINT.lg} {
        &::after {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 134px;
            height: 73px;
            background: url('directions/people.svg');
            content: '';
        }
    }
`;

const StyledCardTitle = styled.h3`
    margin: 0;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 18px;
    font-style: normal;
`;

const StyledBigNumber = styled(StyledCardTitle)`
    color: ${COLORS.primary};
    font-size: 40px;
    line-height: 52px;

    ${BREAKPOINT.sm} {
        margin-bottom: auto;
        font-size: 56px;
        line-height: 72px;
    }

    ${BREAKPOINT.lg} {
        margin-bottom: 8px;
    }
`;

const StyledMobileSlider = styled(Splide)`
    ${BREAKPOINT.lg} {
        display: none;
    }

    .splide__track {
        ${BREAKPOINT.md} {
            margin-bottom: 26px;
            padding: 0;
        }
    }

    .splide__slide {
        display: flex;
        flex-direction: column;
    }
`;

const StyledDesktopGrid = styled(Row)`
    display: none;

    ${BREAKPOINT.lg} {
        --gutter: 8px;

        display: flex;
    }

    ${Col} {
        display: flex;
        flex-direction: column;
    }
`;

const StyledSomeDesktopGrid = styled(StyledDesktopGrid)`
    ${BREAKPOINT.lg} {
        margin-top: 16px;
    }
`;

const StyledFullContent = styled.div`
    display: flex;
    flex-direction: ${({ direction }) => (direction === 'row' ? 'row' : 'column')};
    flex-grow: 1;
    align-items: ${({ direction }) => (direction === 'row' ? 'center' : 'flex-start')};
    height: 100%;
    padding: 32px;
    background: #fff;
    border-radius: 20px;

    ${BREAKPOINT.lg} {
        flex-direction: column;
        align-items: flex-start;
        height: initial;
        margin-top: 0;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    img {
        margin-bottom: 8px;
    }

    span {
        color: rgba(56 70 70 / 50%);
        font-weight: 400;
        font-size: 14px;
        font-style: normal;
        line-height: 18px;
    }
`;

const StyledStatistycContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 24px 32px;
    border: 1px solid rgba(0 131 229 / 50%);
    border-radius: 20px;

    ${BREAKPOINT.sm} {
        min-height: 166px;
    }

    ${BREAKPOINT.lg} {
        --gutter: 8px;

        height: initial;
        min-height: auto;
        margin-top: 0;

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    span {
        color: rgba(56 70 70 / 50%);
        font-weight: 400;
        font-size: 18px;
        font-style: normal;
        line-height: 1;
    }
`;
