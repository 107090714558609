export const DATA = {
    title: 'Наши проекты',
    arrayProjects: [
        {
            id: 1,
            title: 'Moneyman&nbsp;RU',
            domen: 'moneyman.ru',
            subtitle: 'Краткосрочные займы “до зарплаты”',
            description: 'Крупнейший онлайн-сервис альтернативного кредитования в России по выдаче PDL-займов',
            since: {
                year: '2011',
                caption: 'Год основания',
            },
            info: {
                amount: '6,5 млн',
                namely: 'Пользователи',
            },
        },
        {
            id: 2,
            title: 'Solva',
            domen: 'solva.kz',
            subtitle: 'Потребкредитование и<br/> финансирование макро- и малого бизнеса',
            description: 'Первый и крупнейший онлайн-сервис альтернативного кредитования в Казахстане',
            since: {
                year: '2017',
                caption: 'Год основания',
            },
            info: {
                amount: '1 млн',
                namely: 'Пользователи',
            },
        },
        {
            id: 3,
            title: 'ID Collect',
            domen: 'idcollect.ru',
            subtitle: 'Коллекторское агентство',
            description:
                'Профессиональное коллекторское агентство, специализирующееся на возврате просроченной задолженности с использованием новейших технологий',
            since: {
                year: '2017',
                caption: 'Год основания',
            },
            info: {
                amount: '2,6&nbsp;млрд&nbsp;₽',
                namely: 'Объем возврата<br/> задолженности',
            },
        },
        {
            id: 4,
            title: 'Moneyman&nbsp;KZ',
            domen: 'moneyman.kz',
            subtitle: 'Микрокредиты онлайн (PDL-займы “до зарплаты”)',
            description: 'Крупнейший в Казахстане сервис альтернативного кредитования',
            since: {
                year: '2014',
                caption: 'Год основания',
            },
            info: {
                amount: '1,7 млн',
                namely: 'Пользователи',
            },
        },
    ],
    quote: {
        text: 'Мы эффективно справляемся с поставленными задачами в сферах',
        subtext: '<span>B2B</span><span>B2C</span><span>B2B2C</span>',
    },
};
